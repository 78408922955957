import React from 'react'
import { Link } from 'gatsby'
import { ASSETS, routes } from '@helpers'

const { Permainan, SabunChampaca, BukuBuku, KatalogEbooks } = ASSETS.svg

const navs = routes.map(i => ({
  Component:
    i.label === 'Permainan'
      ? Permainan
      : i.label === 'Sabun'
      ? SabunChampaca
      : i.label === 'Buku-buku'
      ? BukuBuku
      : i.label === 'Katalog & Ebooks'
      ? KatalogEbooks
      : 'Parameter is not a number!',
  ...i,
}))

export const MainNav = () => (
  <section className='flex flex-col sm:flex-row gap-5 justify-center items-center flex-wrap'>
    {navs.map(({ to, label, Component }, i) => (
      <Link to={to} key={i} title={label}>
        <div className='bg-gradient-to-r from-c-primary-i-500 to-c-primary-500 border-2 border-c-primary-700 hover:shadow-lg px-5 pt-5 rounded relative'>
          <Component width={200} height={200} />
          <div className='absolute bottom-0 left-0 bg-white text-c-primary-700 px-2 pt-1 rounded-tr'>
            <p>{label}</p>
          </div>
        </div>
      </Link>
    ))}
  </section>
)
