import React, { useState } from 'react'
import Modal, { OnAfterOpenCallbackOptions } from 'react-modal'
import { customStyles, GridItem, Slideshow } from './ModalWithSlider'

export function ModalWithSlider({ nodes, RenderItem }: any) {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false)
  const [defaultIndex, setDefaultIndex] = useState<number>(0)

  function openModal(sliderItemIndex: number) {
    document.getElementById('main-nav')!.classList.add('hidden')
    setIsOpen(true)
    setDefaultIndex(sliderItemIndex)
  }

  function closeModal() {
    document.getElementById('main-nav')!.classList.remove('hidden')
    setIsOpen(false)
  }

  function onAfterOpen({ overlayEl }: OnAfterOpenCallbackOptions | any) {
    overlayEl.insertAdjacentHTML(
      'afterbegin',
      `<svg
        viewBox='0 0 24 24'
        class='absolute cursor-pointer p-1 z-10 rounded-full hover:bg-gray-500'
        style="width: 30px; height: 30px; top: 10px; right: 10px">
        <path
          fill='white'
          d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z'
        />
      </svg>`
    )
  }

  return (
    <>
      <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-4 sm:gap-8 grid-flow-row-dense'>
        {nodes.map((item: { id: string } | any, id: number) => (
          <GridItem
            key={id}
            index={id}
            item={item}
            RenderItem={RenderItem}
            openModal={openModal}
          />
        ))}
      </ul>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          onAfterOpen={onAfterOpen}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick
          contentLabel='Modal Images with slider'>
          <Slideshow defaultIndex={defaultIndex} items={nodes} />
        </Modal>
      )}
    </>
  )
}
