import { ga } from '@helpers/ga'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Portal2 = ({ children }: any) => {
  const mount: any = document.getElementById('portal-image')
  return createPortal(children, mount)
}

export const LightModalWrapper = ({
  children,
  altText,
  fluidImgSharp,
}: any) => {
  const [isModalOpen, setModal] = useState<boolean>(false)

  function onEscape(e: KeyboardEvent) {
    if (e.code === 'Escape') {
      closeModal()
    }
  }

  const openModal = () => {
    setModal(true)

    ga('event', 'click', {
      event_category: 'img-modal',
      event_label: altText,
    })()

    document.addEventListener('keyup', onEscape)
  }

  const closeModal = () => {
    setModal(false)
    document.removeEventListener('keyup', onEscape)
  }

  return (
    <>
      <div className='h-full' onClick={openModal}>
        {children}
      </div>
      {isModalOpen && (
        <Portal2>
          <div
            className='fixed w-screen border-red-600'
            style={{
              top: '64px',
              height: 'calc(100vh - 64px)',
            }}>
            <div className='bg-black h-full w-full absolute opacity-75' />
            <div className='absolute right-0 flex items-center bg-black w-full py-2'>
              <p className='text-white w-full capitalize text-center rounded pointer-events-none'>
                {altText}
              </p>
              <svg
                onClick={closeModal}
                viewBox='0 0 24 24'
                className='cursor-pointer top-0 mr-2 z-20'
                style={{ width: '25px', height: '25px' }}>
                <path
                  fill='#fff'
                  d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z'
                />
              </svg>
            </div>
            <div
              className='flex relative items-center justify-center w-full z-10'
              style={{ height: 'calc(100vh - 40px)' }}>
              <GatsbyImage
                image={fluidImgSharp}
                alt={altText}
                imgStyle={{
                  objectFit: 'contain',
                }}
                className='object-contain'
                style={{
                  height: '100%',
                  width: '100%',
                  maxWidth: '90%',
                  maxHeight: 'calc(100vh - 150px)',
                }}
              />
            </div>
          </div>
        </Portal2>
      )}
    </>
  )
}
