export const routes = [
  {
    label: 'Permainan',
    to: '/permainan',
  },
  {
    label: 'Sabun',
    to: '/sabun',
  },
  {
    label: 'Buku-buku',
    to: '/buku-buku',
  },
  {
    label: 'Katalog & Ebooks',
    to: '/katalog-ebooks',
  },
]
