import React from 'react'
import * as MainTitleStyle from './main-title.module.scss'

export const MainTitle = ({
  title,
  subtitle = '',
}: {
  title: string
  subtitle?: string
}) => (
  <>
    <h1
      // @ts-ignore
      style={{ fontDisplay: 'swap' }}
      className={`text-center text-5xl sm:text-6xl text-black font-great-vibes ${MainTitleStyle.heroTitle}`}>
      {title}
    </h1>

    {subtitle && (
      <h1
        // @ts-ignore
        style={{ fontDisplay: 'swap' }}
        className={`text-center text-3xl mb-10 mt-5 ${MainTitleStyle.heroTitle}`}>
        {subtitle}
      </h1>
    )}
  </>
)
