import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

interface MetaHeader {
  url?: string
  imgUrl?: string
  title: string
  description: string
}

export function MetaHeader({ title, description, imgUrl, url }: MetaHeader) {
  const {
    site: {
      siteMetadata: { domain },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          domain
        }
      }
    }
  `)

  if (!url) {
    url = domain
  }

  if (!imgUrl) {
    imgUrl = `${domain}/img/logo.jpg`
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='title' content={title} />
      <meta name='description' content={description} />

      {/* facebook */}
      <meta property='og:type' content='website' />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={title} />
      <meta property='og:image' content={imgUrl} />
      <meta property='og:description' content={description} />

      {/* Twitter */}
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={url} />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:image' content={imgUrl} />
      <meta property='twitter:description' content={description} />
    </Helmet>
  )
}
