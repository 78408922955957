import React from 'react'
import { WhatsappBtn } from '@components'
import * as FooterStyle from './footer.module.scss'

export function Footer({ children }: any) {
  return (
    <footer className={`bg-white shadow-2xl ${FooterStyle.footer}`}>
      <div className={`${FooterStyle.hasTopDivider}`} />
      <div className='flex'>
        <div className='flex-1 max-w-4xl mx-auto px-10'>
          <ul className='list-none my-3 md:my-0 flex-wrap flex justify-around items-center'>
            {children}
            <li className='block sm:hidden'>
              <WhatsappBtn iconSize={36} classNames='block sm:hidden' />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
