type GA = (
  event: 'event',
  action: 'click',
  option: Partial<{
    event_category: string
    event_label: string
  }>
) => (e?: any) => void

export const ga: GA =
  (...params) =>
  _ => {
    typeof window !== 'undefined' && window.gtag && window.gtag(...params)
  }

// ga('event', 'click', {
//   event_category: 'download-katalog-ebooks',
//   event_label: 'title',
// })
