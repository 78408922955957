import React from 'react'
import { Link } from 'gatsby'
import { routes } from '@helpers'
import { Location as RouterLocation } from '@reach/router'

const BreadcrumbInner = ({ location }: { location: Location }): JSX.Element => {
  const removedDomain = location.href.replace(location.origin, '')
  let middlePaths = removedDomain.split('/').filter(Boolean)
  let last = middlePaths.pop()

  // @ts-ignore
  const nestedPath: { to: string; label: string }[] = middlePaths
    .map(path => routes.find(route => route.to === `/${path}`))
    .filter(Boolean)

  // if last is part of routes then use routes Label
  last =
    nestedPath.length <= 0
      ? routes.find(route => route.to === `/${last}`)?.label
      : last

  return (
    <nav className='inline-flex md:block bg-white p-3 rounded mb-10 shadow'>
      <ol className='hidden md:flex list-reset items-center'>
        <li className='inline-flex'>
          <Link
            to='/'
            className={`text-c-primary-700 inline-flex items-center`}>
            <svg style={{ width: '25px', height: '25px' }} viewBox='0 0 24 24'>
              <title>Halaman Utama</title>
              <path
                fill='#938D7A'
                d='M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z'
              />
            </svg>
            <p className='block md:hidden ml-2'>Home</p>
          </Link>
        </li>
        <li>
          <span className='mx-2 text-c-primary-800'>/</span>
        </li>
        {nestedPath.map(({ label, to }, i) => (
          <React.Fragment key={i}>
            <li>
              <Link to={to} className='text-c-primary-700 hover:underline'>
                {label}
              </Link>
            </li>
            <li>
              <span className='mx-2 text-c-primary-800'>/</span>
            </li>
          </React.Fragment>
        ))}
        <li className='text-c-primary-700 font-bold capitalize'>{last}</li>
      </ol>

      {/* small screen */}
      <ol className='inline-flex md:hidden list-reset items-center'>
        {!nestedPath[nestedPath.length - 1] && (
          <li className='inline-flex'>
            <Link
              to='/'
              className={`text-c-primary-700 inline-flex items-center`}>
              <svg
                style={{ width: '25px', height: '25px' }}
                viewBox='0 0 24 24'>
                <path
                  fill='#938D7A'
                  d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z'
                />
              </svg>
              <svg
                style={{ width: '25px', height: '25px' }}
                viewBox='0 0 24 24'>
                <title>Halaman Utama</title>
                <path
                  fill='#938D7A'
                  d='M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z'
                />
              </svg>
              <p className='block md:hidden ml-2'>Home</p>
            </Link>
          </li>
        )}
        {nestedPath[nestedPath.length - 1] && (
          <li
            className='inline-flex'
            title={`Kembali pada ${nestedPath[nestedPath.length - 1].label}`}>
            <Link
              to={nestedPath[nestedPath.length - 1].to}
              className='flex text-c-primary-700 hover:underline font-bold'>
              <svg
                style={{ width: '25px', height: '25px' }}
                viewBox='0 0 24 24'>
                <path
                  fill='#938D7A'
                  d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z'
                />
              </svg>
              {nestedPath[nestedPath.length - 1].label}
            </Link>
          </li>
        )}
      </ol>
    </nav>
  )
}

export const Breadcrumb = (): JSX.Element => {
  return (
    <RouterLocation>
      {({ location }) => {
        return typeof window !== 'undefined' ? (
          <BreadcrumbInner location={location} />
        ) : null
      }}
    </RouterLocation>
  )
}
