// tslint:disable:max-line-length
import logo from './../../static/img/logo.jpg'
import bookBanner from './../../static/img/buku-edu-online.jpeg'

import BukuBuku from './../../static/img/buku_buku.svg'
import SabunChampaca from './../../static/img/sabun.svg'
import Permainan from './../../static/img/permainan.svg'
import KatalogEbooks from './../../static/img/katalog_ebooks.svg'

import sabun from './../../static/mp4/sabun.mp4'
import promo1 from './../../static/mp4/promo1.mp4'
import promo2 from './../../static/mp4/promo2.mp4'
import promo3 from './../../static/mp4/promo3.mp4'
import promo4 from './../../static/mp4/promo4.mp4'
import terpesona from './../../static/mp4/terpesona.mp4'
import sinkSumbat from './../../static/mp4/sink-sumbat.mp4'
import pipe_building_block from './../../static/mp4/pipe_building_block.mp4'
import demo_keberkesanan_sabun_champaca_1 from './../../static/mp4/demo_keberkesanan_sabun_champaca_1.mp4'
import demo_keberkesanan_sabun_champaca_2 from './../../static/mp4/demo_keberkesanan_sabun_champaca_2.mp4'
import demo_keberkesanan_sabun_champaca_3 from './../../static/mp4/demo_keberkesanan_sabun_champaca_3.mp4'
import demo_keberkesanan_sabun_champaca_4 from './../../static/mp4/demo_keberkesanan_sabun_champaca_4.mp4'
import demo_keberkesanan_sabun_champaca_5 from './../../static/mp4/demo_keberkesanan_sabun_champaca_5.mp4'
import demo_keberkesanan_sabun_champaca_6 from './../../static/mp4/demo_keberkesanan_sabun_champaca_6.mp4'
import demo_keberkesanan_sabun_champaca_7 from './../../static/mp4/demo_keberkesanan_sabun_champaca_7.mp4'
import demo_keberkesanan_sabun_champaca_8 from './../../static/mp4/demo_keberkesanan_sabun_champaca_8.mp4'

export const ASSETS = {
  logo,
  bookBanner,
  mp4: {
    sabun,
    terpesona,
    sinkSumbat,
    promo1,
    promo2,
    promo3,
    promo4,
    pipe_building_block,
    demo_keberkesanan_sabun_champaca_1,
    demo_keberkesanan_sabun_champaca_2,
    demo_keberkesanan_sabun_champaca_3,
    demo_keberkesanan_sabun_champaca_4,
    demo_keberkesanan_sabun_champaca_5,
    demo_keberkesanan_sabun_champaca_6,
    demo_keberkesanan_sabun_champaca_7,
    demo_keberkesanan_sabun_champaca_8,
  },
  svg: {
    BukuBuku,
    Permainan,
    KatalogEbooks,
    SabunChampaca,
  },
}
