import React from 'react'
import { Helmet } from 'react-helmet'
import { MetaHeader } from '@components'
import { graphql, useStaticQuery } from 'gatsby'

export function SEO() {
  const {
    site: {
      siteMetadata: { title, domain, description },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          domain
          description
        }
      }
    }
  `)

  return (
    <>
      <MetaHeader
        url={domain}
        title={title}
        description={description}
        imgUrl={`${domain}/img/logo.jpg`}
      />
      <Helmet>
        <meta charSet='utf-8' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#ddd4b7' />
        <meta name='msapplication-TileColor' content='#ddd4b7' />
        <meta name='theme-color' content='#ddd4b7' />

        <html lang='en' />
      </Helmet>
    </>
  )
}
