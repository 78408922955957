import { ASSETS, routes } from '@helpers'
import * as HeaderStyle from './header.module.scss'
import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const newRoutes = routes.filter(({ label }) => label !== 'Katalog & Ebooks')

// @ts-ignore
const katalogEbookRoute: { label: string; to: string } = routes.find(
  ({ label }) => label === 'Katalog & Ebooks'
)

export function Header() {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useEffect(() => {
    function isClickingOutside(e: MouseEvent) {
      // is user clicking outside #main-nav
      if (!(e.target as HTMLTextAreaElement).closest('#main-nav')) {
        setIsDropdownOpen(false)
      }
    }

    window.addEventListener('click', isClickingOutside)
    return () => window.removeEventListener('click', isClickingOutside)
  }, [])

  return (
    <>
      <nav
        id='main-nav'
        className='bg-gradient-to-r from-c-primary-i-500 to-c-primary-500 shadow-md sticky top-0 z-10 relative'>
        <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
          <div className='relative flex items-center justify-between h-16'>
            <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
              <button
                className='inline-flex items-center justify-center p-2 rounded-md text-c-primary-900 hover:text-white hover:bg-c-primary-700 focus:outline-none focus:bg-c-primary-700 focus:text-white transition duration-150 ease-in-out'
                aria-label='Main menu'
                aria-expanded='false'
                onClick={toggleDropdown}>
                <svg
                  className='block h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
                <svg
                  className='hidden h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            <div className='ml-0 sm:ml-12 md:ml-0 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start'>
              <div className='flex-shrink-0'>
                <Link to={'/'} className='w-56 flex items-center'>
                  <img
                    className='block h-10 rounded-full w-auto border-2 border-c-primary-800 shadow'
                    src={ASSETS.logo}
                    alt={title}
                  />
                  <h1
                    // @ts-ignore
                    style={{ fontDisplay: 'swap' }}
                    className={`ml-2 text-center text-2xl font-great-vibes text-c-primary-800 font-extrabold ${HeaderStyle.HeaderTitle}`}>
                    {title}
                  </h1>
                </Link>
              </div>
              <div className='hidden md:block sm:ml-auto'>
                <div className='flex'>
                  {newRoutes.map(({ to, label }, i) => (
                    <Link
                      to={to}
                      key={i}
                      partiallyActive={true}
                      activeClassName='text-c-primary-900 bg-gradient-to-r from-c-primary-i-500 to-c-primary-500 border-c-primary-900'
                      className='mr-1 px-3 py-2 text-c-primary-900 rounded-md text-sm font-medium leading-5 border border-transparent hover:border-c-primary-900 focus:outline-none transition duration-150 ease-in-out'>
                      {label}
                    </Link>
                  ))}
                  <Link
                    to={katalogEbookRoute.to}
                    partiallyActive={true}
                    activeClassName='shadow border-red-500'
                    className='mr-1 px-3 py-2 text-red-800 bg-gradient-to-r from-red-400 to-red-300 rounded-md text-sm font-medium leading-5 border border-transparent hover:shadow hover:border-red-500 focus:outline-none transition duration-150 ease-in-out'>
                    Muat Turun Percuma
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`p-2 space-y-1 md:hidden absolute left-0 w-full bg-gradient-to-r from-c-primary-i-500 to-c-primary-500 ${
            isDropdownOpen ? 'block' : 'hidden'
          }`}>
          {newRoutes.map(({ to, label }, i) => (
            <Link
              to={to}
              key={i}
              partiallyActive={true}
              activeClassName='text-c-primary-900 bg-gradient-to-r from-c-primary-i-500 to-c-primary-500 border-c-primary-900'
              className='block px-3 py-2 text-c-primary-900 rounded-md text-base font-medium text-black border border-transparent hover:border-c-primary-900 focus:outline-none transition duration-150 ease-in-out'>
              {label}
            </Link>
          ))}
          <Link
            to={katalogEbookRoute.to}
            partiallyActive={true}
            activeClassName='shadow border-red-500'
            className='block px-3 py-2 text-red-800 bg-gradient-to-r from-red-400 to-red-300 rounded-md text-base font-medium focus:outline-none transition duration-150 ease-in-out'>
            Muat Turun Percuma
          </Link>
        </div>
      </nav>
      <div className={HeaderStyle.back} />
    </>
  )
}
