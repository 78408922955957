import './VideoSlider.scss'
import React, { useEffect } from 'react'

export const VideoSlider = ({ videos }: any) => {
  useEffect(() => {
    const splide = new Splide('.splide', {
      type: 'loop',
      width: '96vw',
      height: 500,
      gap: '1rem',
      focus: 'center',
      keyboard: false,
      video: {
        loop: true,
      },
    }).mount({ Video: SplideVideo })

    // @ts-ignore
    return () => splide.destroy()
  }, [])

  return (
    <div className='video-slider'>
      <div className='splide'>
        <div className='splide__track'>
          <ul className='splide__list'>
            {videos.map(({ src, ...others }: any, i: number) => (
              <li
                key={i}
                className='splide__slide'
                {...{
                  [others.type === 'youtube'
                    ? 'data-splide-youtube'
                    : 'data-splide-html-video']: src,
                }}>
                {others.type === 'youtube' ? (
                  <img src={others.imgSrc} alt={others.title} />
                ) : (
                  <img
                    src={
                      'https://images.unsplash.com/photo-1541280910158-c4e14f9c94a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=40'
                    }
                    alt='default image'
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
