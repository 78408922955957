import React from 'react'
import Modal from 'react-modal'
import { Header, SEO, WhatsappBtn } from '@components'

Modal.setAppElement('#___gatsby')

export function Layout({ children }: any) {
  return (
    <>
      <SEO />
      <Header />

      <main className='flex-1'>{children}</main>

      <WhatsappBtn />
    </>
  )
}
