import React from 'react'
import { ga } from '@helpers/ga'
import * as FooterStyle from './footer.module.scss'

export function ShopeeIcon({ shopee }: { shopee: string }) {
  return (
    <a
      rel='noreferrer noopener'
      target='_blank'
      href={shopee}
      onClick={ga('event', 'click', {
        event_category: 'cta-footer-shopee',
        event_label: shopee,
      })}
      title='Jom lewati Shopee kami'>
      <p
        className={`hidden md:block text-2xl ${FooterStyle.socialMedia__icon} ${FooterStyle.socialMedia__shopee}`}>
        Shopee
      </p>
      <svg
        className={`block md:hidden ${FooterStyle.socialMedia__icon} ${FooterStyle.socialMedia__shopeeSmall}`}
        xmlns='http://www.w3.org/2000/svg'
        x='0'
        y='0'
        height='48'
        width='48'
        enableBackground='new 0 0 362 535.3'
        version='1.1'
        viewBox='0 0 362 435.3'
        xmlSpace='preserve'>
        <path
          fill='#EA501F'
          d='M36.5,118.1h75.3c2.3-25.5,9.4-48.4,19.6-66.1c13.5-23.4,32.7-37.8,54.4-37.8c21.7,0,40.8,14.5,54.4,37.8
	c10.2,17.6,17.3,40.6,19.6,66.1H335c8.9,0,16.9,7.3,16.2,16.2L336.1,345c-1.9,26.4-18.5,41.5-42.6,41.5H78.1
	c-26.8,0-41-18.7-42.6-41.5L20.3,134.4C19.7,125.5,27.6,118.1,36.5,118.1L36.5,118.1z M130.6,118.1h110.2
	c-2.2-22.1-8.3-41.7-17-56.7C213.8,43.9,200.2,33,185.8,33c-14.5,0-28,10.9-38.2,28.5C138.9,76.4,132.9,96.1,130.6,118.1z'
        />
        <path
          fill='#fff'
          d='M131.1,312.6c-3.5-2.4-4.4-7.1-2-10.6c2.4-3.5,7.1-4.4,10.6-2c2.2,1.5,4.5,3,7,4.5c17.6,10.7,33.8,16,47.3,16
	c12.2,0,22.1-4.6,28.4-13.9v0c0.4-0.7,0.8-1.1,0.9-1.4c1.8-2.9,3.1-6,4-9c1.8-6.2,1.5-12.6-1-18.4c-2.7-6.1-7.9-12-16-16.9l0,0
	c-5.4-3.3-12.1-6.2-20.2-8.4c-20.6-5.8-35.8-14-44-25c-9-12.1-9.7-26.7-0.1-44.4c6.4-11.7,19.5-19,36.1-20.3
	c14.3-1.1,31.5,2.3,49,11.2c3.8,1.9,5.3,6.5,3.3,10.3s-6.5,5.3-10.3,3.3c-14.9-7.6-29.1-10.5-40.9-9.6c-11.3,0.9-19.9,5.3-23.7,12.3
	c-6.4,11.7-6.3,20.9-1.1,27.9c6,8.1,18.5,14.4,35.9,19.3c9.4,2.7,17.4,6.1,24,10.1h0c10.9,6.6,18.2,14.9,22.1,23.9
	c4,9.3,4.5,19.1,1.8,28.7c-1.2,4.3-3.1,8.6-5.7,12.8c-0.6,0.9-1,1.6-1.3,2.1l0,0c-9.3,13.7-23.6,20.5-41,20.6
	c-16.3,0-35.3-6-55.3-18.2C136.4,316.1,133.8,314.5,131.1,312.6z'
        />
      </svg>
    </a>
  )
}
