// extracted by mini-css-extract-plugin
export var socialMedia__icon = "footer-module--socialMedia__icon--1j_BQ";
export var socialMedia__ig = "footer-module--socialMedia__ig--1SdZK";
export var socialMedia__igSmall = "footer-module--socialMedia__igSmall--2HCM2";
export var socialMedia__fb = "footer-module--socialMedia__fb--QxHCJ";
export var socialMedia__fbSmall = "footer-module--socialMedia__fbSmall--3cDjt";
export var socialMedia__shopee = "footer-module--socialMedia__shopee--Ls8ow";
export var socialMedia__shopeeSmall = "footer-module--socialMedia__shopeeSmall--1MeIV";
export var socialMedia__tg = "footer-module--socialMedia__tg--1DxgH";
export var socialMedia__tgSmall = "footer-module--socialMedia__tgSmall--qSWKU";
export var socialMedia__whatsapp = "footer-module--socialMedia__whatsapp--RH7R4";
export var wiggle = "footer-module--wiggle--3z7hH";
export var hasTopDivider = "footer-module--hasTopDivider--195W5";
export var footer = "footer-module--footer--eMWRA";