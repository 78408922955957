import './ModalWithSlider.scss'
import { ga } from '@helpers/ga'
import { Portal } from '@components/Portal/Portal'
import React, { useEffect, useState } from 'react'

export const customStyles = {
  overlay: {
    background: 'rgb(74 71 61 / 95%)',
  },
  content: {
    background: 'transparent',
    padding: '0',
    overflow: 'hidden',
    border: '0px',
  },
}

const getRename = (name: string) =>
  name.replace(/(_|-)/g, ' ').replace(/(other|testimoni|MCO)/gi, '')

export const GridItem = ({ item, openModal, index, RenderItem }: any) => {
  const {
    childImageSharp: { gatsbyImageData },
  } = item

  const aspectRatio = gatsbyImageData.width / gatsbyImageData.height
  const autoSpanGridBasedOnAspectRatio =
    aspectRatio > 1
      ? 'col-span-1 sm:col-span-2'
      : aspectRatio < 1
      ? 'row-span-1 sm:row-span-2'
      : ''

  function handleItem() {
    openModal(index)
  }

  return (
    <li onClick={handleItem} className={autoSpanGridBasedOnAspectRatio}>
      <RenderItem item={item} />
    </li>
  )
}

export const Slideshow = ({
  items,
  defaultIndex,
}: {
  items: any[]
  defaultIndex: number
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(defaultIndex)

  useEffect(() => {
    const splide = new Splide('#ModalWithSlider-splide.splide', {
      type: 'loop',
      start: defaultIndex,
      perPage: 1,
      perMove: 1,
      gap: '1rem',
      lazyLoad: true,
      focus: 'center',
    }).mount()

    splide.on('move', function (newIndex: any) {
      setCurrentIndex(() => newIndex)
      ga('event', 'click', {
        event_category: 'modal-with-slider-onchange',
        event_label: items[newIndex]?.name ?? '',
      })()
    })

    // @ts-ignore
    return () => splide.destroy()
  }, [defaultIndex])

  return (
    <>
      <div id='ModalWithSlider-splide' className='splide'>
        <div className='splide__track'>
          <ul className='splide__list'>
            {items.map((item: any, i: number) => (
              <li className='splide__slide' key={i}>
                <SlideShowItem item={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Portal>
        <div className={`fixed w-full bottom-0 text-center`}>
          <p
            className={`text-white py-1 px-2 mb-2 inline-block rounded bg-c-primary-900`}>
            {getRename(items[currentIndex]?.name) ?? ''}
          </p>
        </div>
      </Portal>
    </>
  )
}

export const SlideShowItem = ({ item }: { item: any }) => {
  const {
    name,
    childImageSharp: { gatsbyImageData },
  } = item

  const renameImg = getRename(name)
  return (
    <img
      className='rounded mx-auto h-full'
      src={gatsbyImageData.images.fallback.src}
      alt={renameImg}
    />
  )
}
