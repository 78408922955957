import React from 'react'
import { Link } from 'gatsby'

export function DownloadCatalog() {
  return (
    <div className='flex flex-col sm:flex-row justify-center items-center'>
      <Link
        to='/katalog-ebooks'
        className='flex px-3 py-2 rounded hover:shadow-lg items-center bg-gradient-to-r from-red-400 to-red-300 border border-red-800'>
        <svg style={{ width: '25px', height: '25px' }} viewBox='0 0 24 24'>
          <path
            fill='#9b2c2c'
            d='M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2M10.1,11.4C10.08,11.44 9.81,13.16 8,16.09C8,16.09 4.5,17.91 5.33,19.27C6,20.35 7.65,19.23 9.07,16.59C9.07,16.59 10.89,15.95 13.31,15.77C13.31,15.77 17.17,17.5 17.7,15.66C18.22,13.8 14.64,14.22 14,14.41C14,14.41 12,13.06 11.5,11.2C11.5,11.2 12.64,7.25 10.89,7.3C9.14,7.35 9.8,10.43 10.1,11.4M10.91,12.44C10.94,12.45 11.38,13.65 12.8,14.9C12.8,14.9 10.47,15.36 9.41,15.8C9.41,15.8 10.41,14.07 10.91,12.44M14.84,15.16C15.42,15 17.17,15.31 17.1,15.64C17.04,15.97 14.84,15.16 14.84,15.16M7.77,17C7.24,18.24 6.33,19 6.1,19C5.87,19 6.8,17.4 7.77,17M10.91,10.07C10.91,10 10.55,7.87 10.91,7.92C11.45,8 10.91,10 10.91,10.07Z'
          />
        </svg>

        <p className='ml-1 text-red-800 text-sm'>
          Muat turun katalog/ebooks percuma
        </p>
      </Link>
    </div>
  )
}
